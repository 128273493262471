import {
	PORTALLOGO_REQUEST,
	PORTALLOGO_SUCCESS,
	PORTALLOGO_FAIL,
	VIPPSAUTHURL_REQUEST,
	VIPPSAUTHURL_SUCCESS,
	VIPPSAUTHURL_FAIL,
	USERINFO_REQUEST,
	USERINFO_SUCCESS,
	USERINFO_FAIL,
	USER_CONSENTSAVE_REQUEST,
	USER_CONSENTSAVE_SUCCESS,
	USER_CONSENTSAVE_FAIL,
	AREA_REQUEST,
	AREA_SUCCESS,
	AREA_FAIL,
	RELATIONSHIP_REQUEST,
	RELATIONSHIP_SUCCESS,
	RELATIONSHIP_FAIL,
	BRANCHES_REQUEST,
	BRANCHES_SUCCESS,
	BRANCHES_FAIL,
	BOOKING_FAIL,
	BOOKING_REQUEST,
	BOOKING_SUCCESS,
	LAWYER_REQUEST,
	LAWYER_SUCCESS,
	LAWYER_FAIL,
	PROFILE_REQUEST,
	PROFILE_SUCCESS,
	PROFILE_FAIL,
	PROFILE_SAVE_REQUEST,
	PROFILE_SAVE_SUCCESS,
	PROFILE_SAVE_FAIL,
	ALL_CASE_REQUEST,
	ALL_CASE_SUCCESS,
	ALL_CASE_FAIL,
	CASE_DETAIL_REQUEST,
	CASE_DETAIL_SUCCESS,
	CASE_DETAIL_FAIL,
	DOCUMENT_LIST_REQUEST,
	DOCUMENT_LIST_SUCCESS,
	DOCUMENT_LIST_FAIL,
	CREATE_THREAD_REQUEST,
	CREATE_THREAD_SUCCESS,
	CREATE_THREAD_FAIL,
	THREAD_LIST_REQUEST,
	THREAD_LIST_SUCCESS,
	THREAD_LIST_FAIL,
	PENDING_SURVEY_REQUEST,
	PENDING_SURVEY_SUCCESS,
	PENDING_SURVEY_FAIL,
	GET_SURVEY_REQUEST,
	GET_SURVEY_SUCCESS,
	GET_SURVEY_FAIL,
	GET_PUBLICSURVEY_REQUEST,
	GET_PUBLICSURVEY_SUCCESS,
	GET_PUBLICSURVEY_FAIL,
	POST_SURVEY_REQUEST,
	POST_SURVEY_SUCCESS,
	POST_SURVEY_FAIL,
	RESET,
	BANKIDURL_REQUEST,
	BANKIDURL_SUCCESS,
	BANKIDURL_FAIL,
	BANKIDUSERINFO_REQUEST,
	BANKIDUSERINFO_FAIL,
	BANKIDUSERINFO_SUCCESS,
	SURVEYCALLBACK_REQUEST,
	SURVEYCALLBACK_SUCCESS,
	SURVEYCALLBACK_FAIL,
	CREATE_CASE_REQUEST,
	CREATE_CASE_SUCCESS,
	CREATE_CASE_FAIL,
	CHECK_GUID_REQUEST,
	CHECK_GUID_SUCCESS,
	CHECK_GUID_FAIL,
	SURVEY_RESPONSE_REQUEST,
	SURVEY_RESPONSE_SUCCESS,
	SURVEY_RESPONSE_FAIL,
	STORE_SURVEY_REQUEST,
	STORE_SURVEY_SUCCESS,
	STORE_SURVEY_FAIL,
	PUBLIC_SURVEY_RESPONSE_REQUEST,
    PUBLIC_SURVEY_RESPONSE_SUCCESS,
    PUBLIC_SURVEY_RESPONSE_FAIL
} from '../constants/App';

const initialState = {
	portalLogo: {},
	vippsAuthInfo: {},
	userInfo: {},
	fetching: false,
	error: '',
	userConsentSave: {},
	area: {},
	relationship: {},
	branches: {},
	lawyer: {},
	booking: {},
	getProfile: {},
	saveProfile: {},
	caseList: {},
	caseDetails: {},
	documentList: {},
	createThread: {},
	allThreadList: {},
	pendingSurveyList: {},
	getSurvey: {},
	getPublicSurvey: {},
	getPublicSurveyResponse:{},
	postSurvey: {},
	bankidUrl: '',
	marketsourcedata: {},
	createcasedata: {},
	getSurveyResponse: {},
	storeSurvey:{}
};

export default function loginPage(state = initialState, action = {}) {
	switch (action.type) {
		case PORTALLOGO_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case PORTALLOGO_SUCCESS:
			return {
				...state,
				portalLogo: action.data,
				fetching: false,
				error: ''
			};
		case PORTALLOGO_FAIL:
			return {
				...state,
				portalLogo: action.data,
				fetching: false,
				error: ''
			};
		case VIPPSAUTHURL_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case VIPPSAUTHURL_SUCCESS:
			return {
				...state,
				vippsAuthInfo: action.data,
				fetching: false,
				error: ''
			};
		case VIPPSAUTHURL_FAIL:
			return {
				...state,
				vippsAuthInfo: action.data,
				fetching: false,
				error: ''
			};
		case BANKIDURL_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case BANKIDURL_SUCCESS:
			return {
				...state,
				bankidUrl: action.data,
				fetching: false,
				error: ''
			};
		case BANKIDURL_FAIL:
			return {
				...state,
				vippsAuthInfo: action.data,
				fetching: false,
				error: ''
			};
		case USERINFO_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case USERINFO_SUCCESS:
			return {
				...state,
				userInfo: action.data,
				fetching: false,
				error: ''
			};
		case USERINFO_FAIL:
			return {
				...state,
				userInfo: action.data,
				fetching: false,
				error: ''
			};
		case BANKIDUSERINFO_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case BANKIDUSERINFO_SUCCESS:
			return {
				...state,
				userInfo: action.data,
				fetching: false,
				error: ''
			};
		case BANKIDUSERINFO_FAIL:
			return {
				...state,
				userInfo: action.data,
				fetching: false,
				error: ''
			};
		case USER_CONSENTSAVE_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case USER_CONSENTSAVE_SUCCESS:
			return {
				...state,
				userConsentSave: action.data,
				fetching: false,
				error: ''
			};
		case USER_CONSENTSAVE_FAIL:
			return {
				...state,
				userConsentSave: action.data,
				fetching: false,
				error: ''
			};
		case AREA_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case AREA_SUCCESS:
			return {
				...state,
				area: action.data,
				fetching: false,
				error: ''
			};
		case AREA_FAIL:
			return {
				...state,
				area: action.data,
				fetching: false,
				error: ''
			};
		case RELATIONSHIP_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case RELATIONSHIP_SUCCESS:
			return {
				...state,
				relationship: action.data,
				fetching: false,
				error: ''
			};
		case RELATIONSHIP_FAIL:
			return {
				...state,
				relationship: action.data,
				fetching: false,
				error: ''
			};
		case BRANCHES_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case BRANCHES_SUCCESS:
			return {
				...state,
				branches: action.data,
				fetching: false,
				error: ''
			};
		case BRANCHES_FAIL:
			return {
				...state,
				branches: action.data,
				fetching: false,
				error: ''
			};
		case LAWYER_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case LAWYER_SUCCESS:
			return {
				...state,
				lawyer: action.data,
				fetching: false,
				error: ''
			};
		case LAWYER_FAIL:
			return {
				...state,
				lawyer: action.data,
				fetching: false,
				error: ''
			};
		case BOOKING_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case BOOKING_SUCCESS:
			return {
				...state,
				booking: action.data,
				fetching: false,
				error: ''
			};
		case BOOKING_FAIL:
			return {
				...state,
				booking: action.data,
				fetching: false,
				error: ''
			};
		case PROFILE_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case PROFILE_SUCCESS:
			return {
				...state,
				getProfile: action.data,
				fetching: false,
				error: ''
			};
		case PROFILE_FAIL:
			return {
				...state,
				getProfile: action.data,
				fetching: false,
				error: ''
			};
		case PROFILE_SAVE_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case PROFILE_SAVE_SUCCESS:
			return {
				...state,
				saveProfile: action.data,
				fetching: false,
				error: ''
			};
		case PROFILE_SAVE_FAIL:
			return {
				...state,
				saveProfile: action.data,
				fetching: false,
				error: ''
			};
		case ALL_CASE_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case ALL_CASE_SUCCESS:
			return {
				...state,
				caseList: action.data,
				fetching: false,
				error: ''
			};
		case ALL_CASE_FAIL:
			return {
				...state,
				caseList: action.data,
				fetching: false,
				error: ''
			};
		case CASE_DETAIL_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case CASE_DETAIL_SUCCESS:
			return {
				...state,
				caseDetails: action.data,
				fetching: false,
				error: ''
			};
		case CASE_DETAIL_FAIL:
			return {
				...state,
				caseDetails: action.data,
				fetching: false,
				error: ''
			};
		case DOCUMENT_LIST_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case DOCUMENT_LIST_SUCCESS:
			return {
				...state,
				documentList: action.data,
				fetching: false,
				error: ''
			};
		case DOCUMENT_LIST_FAIL:
			return {
				...state,
				documentList: action.data,
				fetching: false,
				error: ''
			};
		case CREATE_THREAD_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case CREATE_THREAD_SUCCESS:
			return {
				...state,
				createThread: action.data,
				fetching: false,
				error: ''
			};
		case CREATE_THREAD_FAIL:
			return {
				...state,
				createThread: action.data,
				fetching: false,
				error: ''
			};
		case THREAD_LIST_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case THREAD_LIST_SUCCESS:
			return {
				...state,
				allThreadList: action.data,
				fetching: false,
				error: ''
			};
		case THREAD_LIST_FAIL:
			return {
				...state,
				allThreadList: action.data,
				fetching: false,
				error: ''
			};
		case PENDING_SURVEY_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case PENDING_SURVEY_SUCCESS:
			return {
				...state,
				pendingSurveyList: action.data,
				fetching: false,
				error: ''
			};
		case PENDING_SURVEY_FAIL:
			return {
				...state,
				pendingSurveyList: action.data,
				fetching: false,
				error: ''
			};
		case GET_SURVEY_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case GET_SURVEY_SUCCESS:
			return {
				...state,
				getSurvey: action.data,
				fetching: false,
				error: ''
			};
		case GET_SURVEY_FAIL:
			return {
				...state,
				getSurvey: action.data,
				fetching: false,
				error: ''
			};
		case SURVEYCALLBACK_REQUEST:
			return {
				...state,
				callback: action,
				fetching: true,
				error: ''
			};
		case SURVEYCALLBACK_SUCCESS:
			return {
				...state,
				callback: action,
				fetching: false,
				error: ''
			};
		case SURVEYCALLBACK_FAIL:
			return {
				...state,
				callback: action,
				fetching: false,
				error: ''
			};
		case GET_PUBLICSURVEY_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case GET_PUBLICSURVEY_SUCCESS:
			return {
				...state,
				getPublicSurvey: action.data,
				fetching: false,
				error: ''
			};
		case GET_PUBLICSURVEY_FAIL:
			return {
				...state,
				getPublicSurvey: action.data,
				fetching: false,
				error: ''
			};
		case POST_SURVEY_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case POST_SURVEY_SUCCESS:
			return {
				...state,
				postSurvey: action.data,
				fetching: false,
				error: ''
			};
		case POST_SURVEY_FAIL:
			return {
				...state,
				postSurvey: action.data,
				fetching: false,
				error: ''
			};
		
		// Store Survey Reducer

		case STORE_SURVEY_REQUEST:
			return {
				...state,
				fetching: true,
				error: ''
			};
		case STORE_SURVEY_SUCCESS:
			
			
			return {
				...state,
				storeSurvey: action.data,
				fetching: false,
				error: ''
			};
		case STORE_SURVEY_FAIL:
			return {
				...state,
				storeSurvey: action.data,
				fetching: false,
				error: ''
			};

		// check marketsource guid
		case CHECK_GUID_REQUEST:
			return {
				...state,
				fetching: true,
			}
		case CHECK_GUID_SUCCESS:

			return {

				...state,
				marketsourcedata: action.data,
				fetching: false,

			}
		case CHECK_GUID_FAIL:

			return {
				...state,
				marketsourcedata: action.data,
				fetch: false,
				error: ''
			}

		// create case
		case CREATE_CASE_REQUEST:
			return {
				...state,
				fetching: true,
			}
		case CREATE_CASE_SUCCESS:

			return {
				...state,
				createcasedata: action.data,
				fetching: false,
			}
		case CREATE_CASE_FAIL:
			return {
				...state,
				fetch: true,
				createcasedata: action.data,
				error: ''

			};
		
		// set survey response answer
		case SURVEY_RESPONSE_REQUEST:
			return {
				...state,
				fetch: true,
			}
		case SURVEY_RESPONSE_SUCCESS:	

			
			return {
				...state,
				getSurveyResponse: action.data,
				fetch: false,
			}
		case SURVEY_RESPONSE_FAIL:
			return {
				...state,
				fetch: false,
				getSurveyResponse: action.data,
				error: ''
			}
		
		// set public survey response
		case PUBLIC_SURVEY_RESPONSE_REQUEST:
			return {
				...state,
				fetch: true,
			}
		case PUBLIC_SURVEY_RESPONSE_SUCCESS:	
			return {
				...state,
				getPublicSurveyResponse: action.data,
				fetch: false,
			}
		case PUBLIC_SURVEY_RESPONSE_FAIL:
			return {
				...state,
				fetch: false,
				getPublicSurveyResponse: action.data,
				error: ''
			}

		case RESET:
			return {
				vippsAuthInfo: {},
				userInfo: {},
				fetching: false,
				error: '',
				userConsentSave: {},
				area: {},
				relationship: {},
				branches: {},
				lawyer: {},
				booking: {},
				getProfile: {},
				saveProfile: {},
				caseDetails: {},
				caseList: {},
				documentList: {},
				createThread: {},
				allThreadList: {},
				pendingSurveyList: {},
				getSurvey: {},
				postSurvey: {},
				portalLogo: {},
				getPublicSurvey: {},
				getPublicSurveyResponse:{},
				getSurveyResponse: {},
				storeSurvey:{}

			};



		default:
			return state;
	}
}